export const days = ['naa', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes']

// Date.getDay() -> 1=Monday, 2=...
export const menus = {
  1: {
    entrantes: [
      {name:'Ensalada con queso de cabra'},
      {name:'Ensalada de habichuelas verdes'},
      {name:'Ensalada tabule'}
    ],
    primeros: [
      {name:'Gazpachuelo'},
      {name:'Berzas de habichuelas verdes'},
      {name:'Sopa de verduras con o sin pollo'}     
    ],
    segundos: [
      {name:'Arroz blanco'},
      {name:'Arroz salteado'},
      {name:'Guisantes con huevo'},
      {name:'Magro con tomate y patatas'},
      {name:'Macarrones con atún, huevo y queso'},
      {name:'Sartena'},
      {name:'Pollo con tomate'},
      {name:'Patatas bravas'},
      {name:'Salchichas encebolladas con patatas'},
      {name:'Verduras salteadas'}
    ]
  },
  2: {
    entrantes: [
      {name:'Ensalada de salmón'}
    ],
    primeros: [
      {name:'Lentejas'},
      {name:'Migas'},
      {name:'Paella'},
      {name:'Puré de verduras'}
      
    ],
    segundos: [
      {name:'Arroz blanco'},
      {name:'Carrillada de cerdo con patatas'},
      {name:'Espirales al pil pil'},
      {name:'Filetitos de lomo en salsa strogonoff con patatas'},
      {name:'Alitas con patatas'},
      {name:'Pollo al curry con patatas o arroz'},
      {name:'Verduras salteadas'}
    ]
  },
  3: {
    entrantes: [
      {name:'Ensalada de coliflor'},
      {name:'Ensalada de pipirrana'},
      {name:'Ensalada de salmón'}
    ],
    primeros: [
      {name:'Cous-cous de ternera'},
      {name:'En blanco (pescado cocido)'},
      {name:'Fideuá'}
    ],
    segundos: [
      {name:'Churrasco de pollo con patatas'},
      {name:'Arroz blanco'},
      {name:'Arroz a la cubana'},
      {name:'Albóndigas en salsa de almendras con patatas'},
      {name:'Albóndigas con tomate y patatas'},
      {name:'Lomo strogonoff'},
      {name:'Salteado de verduras'}
    ]
  },
  4: {
    entrantes: [
      {name:'Ensalada malagueña con atún'},
      {name:'Ensalada malagueña con bacalao'},
    ],
    primeros: [
      {name:'Estofado con patatas'},
      {name:'Fideos en puchero'},
      {name:'Cazuela de patatas con ternera'}
    ],
    segundos: [
      {name:'Arroz blanco'},
      {name:'Arroz salteado con champiñones y pollo'},
      {name:'Lomo a la pimienta con patatas'},
      {name:'Pollo al ajillo con arroz o patatas'},
      {name:'Tallarines de pollo con verduras'},
      {name:'Tortilla de patatas'},
      {name:'Salteado de verduras'},
    ]
  },
  5: {}
}


/*
  Martes
*/
export const entrantes = [
]
export const primeros = [
]
export const segundos = [
]
import React, { Component } from 'react';
import { FaInfoCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './Today.css';
import { menus, days } from './data';


class Item extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='itemDetail' key={this.props.index}>
        {this.props.name}
      </div>
    )
  }
}

class Today extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: true,
      entrantes: true,
      primeros: true,
      segundos: true,
      fechaMenu: ''
    }
    this.setTodos = this.setTodos.bind(this);
    this.setEntrantes = this.setEntrantes.bind(this);
    this.setPrimeros = this.setPrimeros.bind(this);
    this.setSegundos = this.setSegundos.bind(this);
    this.dayString = '';
  }
  componentDidMount() {
    this.setState({
      fechaMenu: new Date().toLocaleDateString('es-ES'),
    })
    this.dayString = days[new Date().getDay()];
  }
  setTodos() {
    this.setState({
      todos: true,
      entrantes: true,
      primeros: true,
      segundos: true
    })
  }
  setEntrantes() {
    this.setState({
      todos: false,
      entrantes: true,
      primeros: false,
      segundos: false
    })
  }
  setPrimeros() {
    this.setState({
      todos: false,
      entrantes: false,
      primeros: true,
      segundos: false
    })
  }
  setSegundos() {
    this.setState({
      todos: false,
      entrantes: false,
      primeros: false,
      segundos: true
    })
  }
  render() {
    let today = new Date().getDay();
    if (today < 1 || today > 5) {
      today = 1;
    } 
    if (menus[today].entrantes === 'undefined') {
      return ( <p>Error</p> )
    }
    return (
      <div>
        <h2>Menú {this.dayString} {this.state.fechaMenu}</h2>
        <div className='boxFilters'>
          <button class={this.state.todos ? 'box selected' : 'box'} type="button" onClick={this.setTodos}><span className='button-text'>Todos</span></button>
          <button class={this.state.entrantes ? 'box selected' : 'box'} type="button" onClick={this.setEntrantes}><span className='button-text'>Entrantes</span></button>
          <button class={this.state.primeros ? 'box selected' : 'box'} type="button" onClick={this.setPrimeros}><span className='button-text'>Primeros</span></button>
          <button class={this.state.segundos ? 'box selected' : 'box'} type="button" onClick={this.setSegundos}><span className='button-text'>Segundos</span></button>
        </div>
        <div>
          {this.state.entrantes && <h3>Entrantes</h3>}
          {this.state.entrantes && menus[today].entrantes.map( ( {name}, index ) => { return <Item key={index} name={name}/> })}
          
          {this.state.primeros && <h3>Primeros</h3>}
          {this.state.primeros && menus[today].primeros.map( ( {name}, index ) => { return <Item key={index} name={name}/> })}
          {this.state.segundos && <h3>Segundos</h3>}
          {this.state.segundos && menus[today].segundos.map( ( {name}, index ) => { return <Item key={index} name={name}/> })}
        </div>
      </div>
    );
  }
}

export default Today;
